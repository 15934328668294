const INITIAL_STATE =
    {
        didx:0,
        openDetailsDlg: false,
    };
const mapit = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_MAPIT_STATE': {
            return INITIAL_STATE;
        }
        case 'SET_OPEN_DETAILS_DLG': {
            return Object.assign({}, state, {openDetailsDlg:action.state});
        }
        case 'SET_DETAILS_IDX': {
            return Object.assign({}, state, {didx:action.didx});
        }
        default:
            return state
    }
};

export default mapit
