import React from 'react';
import ReactImageMosaic from "../mosaic";
import Dialog from "@mui/material/Dialog";
import {Navigate} from "react-router-dom";

class Mosaic extends React.Component{
    componentDidMount() {
        var sources = this.props.transfers.map((source)=>{
            return 'https://media.pimagic.com/'+source.mediaFile+'-sm.png'
        });
        console.log('NEW IMAGE:'+this.props.image);
        sources.push('https://media.pimagic.com/'+this.props.image+'-sm.png')
        this.props.setMosaicSources(sources);
    }
    mosaicTap(tapped){
        //console.log('column, row, color:'+tapped.column +' : '+ tapped.row+' : '+ tapped.color);
        var tappedImageUrl = tapped.image.src;
        console.log('tapped.image.src:'+tappedImageUrl);
        var bigImgUrl = tappedImageUrl.substr(0,tappedImageUrl.length-6)+'out.png';
        console.log('bigImgUrl:'+bigImgUrl);
        this.props.setTappedImage(bigImgUrl);
        this.props.setOpenMosaicDetailsDlg(true);
    }
    handleCloseMosaicDetails(){
        this.props.setOpenMosaicDetailsDlg(false);
    }
    render() {
        return (
            <>
                {!this.props.cid && <Navigate to="/" />}
                {this.props.sources.length > 0 &&
                <div className='hvcentered'>
                    <ReactImageMosaic
                        onClick={(info)=>this.mosaicTap(info)}
                        width={ this.props.screenWidth }
                        height={ this.props.screenWidth }
                        sources={ this.props.sources }
                        target={ 'https://media.pimagic.com/mosaicBase.png' }
                    />
                    <Dialog open={this.props.openMosaicDetailsDlg} onClose={(e)=>this.handleCloseMosaicDetails()} onClick={(e)=>this.handleCloseMosaicDetails()}>
                        <>
                            {this.props.tappedImage &&
                                <>
                                    <img className='hcentered' alt='details' src={this.props.tappedImage}/>
                                </>
                            }
                        </>
                    </Dialog>
                </div>
                }
            </>
        );
    }
}
export default Mosaic
