import { connect } from 'react-redux'
import Transfer from '../components/Transfer'
import {
    initTransfer, mediaPickerAttachmentChanges, mediaPickerClear, addTransfer,
    setTransferLoc, setTransferLabel, setTransferText, setTransferImage, setTransferVideo,
    getPosition
} from '../actions'

const mapStateToProps = (state, props) => {
    return{
        browserInfo:state.local.browserInfo,
        error:state.api.error,
        errorMsg:state.api.errorMsg,
        canceled:state.api.canceled,
        mpError:state.mediapicker.error,
        mpErrorMsg:state.mediapicker.errorMsg,
        attachmentthumbinfo:state.mediapicker.attachmentthumbinfo,
        lng:state.transfer.lng,
        lat:state.transfer.lat,
        label:state.transfer.label,
        text:state.transfer.text,
        image:state.transfer.image,
        video:state.transfer.video,
        transfered:state.transfer.transfered,
        cid:state.transfers.cid,

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        init:() => {
            dispatch(initTransfer());
        },
        getPosition:() => {
            dispatch(getPosition());
        },
        onFilePicked:(evt) => {
            dispatch(mediaPickerClear());
            var fileList = evt.target.files;
            var files = [];
            for (var i = 0; i < fileList.length; i++) {
                files.push(fileList[i])
            }
            dispatch(mediaPickerAttachmentChanges(files));
        },
        clearAttachments:()=>{
            dispatch(mediaPickerClear())
        },
        addTransfer:() => {
            dispatch(addTransfer());
        },
        setTransferLoc:() => {
            dispatch(setTransferLoc());
        },
        setTransferLabel:() => {
            dispatch(setTransferLabel());
        },
        setTransferText:() => {
            dispatch(setTransferText());
        },
        setTransferImage:() => {
            dispatch(setTransferImage());
        },
        setTransferVideo:() => {
            dispatch(setTransferVideo());
        },
    };
};

const TransferContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Transfer);

export default TransferContainer
