import { connect } from 'react-redux'
import MapIt from '../components/MapIt'
import {
    setOpenDetailsDlg,
    setDetailsIdx
} from '../actions'

const mapStateToProps = (state, props) => {
    return{
        transfers:state.transfers.info,
        openDetailsDlg:state.mapit.openDetailsDlg,
        didx:state.mapit.didx,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOpenDetailsDlg:(state) => {
            dispatch(setOpenDetailsDlg(state));
        },
        setDetailsIdx:(didx) => {
            dispatch(setDetailsIdx(didx));
        },
    };
};

const MapItContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(MapIt);

export default MapItContainer
