const INITIAL_STATE =
    {
        activeTab:0,
        profile:{},
        profileMenuIsOpen:false
    };
const profile = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SIGN_OUT':
        case 'INIT_USER_STATE': {
            return INITIAL_STATE;
        }
        case 'SET_ACTIVE_TAB':
        {
            return Object.assign({}, state, {activeTab:action.tab});
        }
        /*case 'GET_USER_PROFILE':
        {
            return Object.assign({}, state, {profile: null});
        }*/
        case 'GOT_USER_PROFILE':
        {
            return Object.assign({}, state, {profile:action.profile});
        }
        case 'GOT_CREATED':
        {
            return Object.assign({}, state, {created:action.created});
        }
        case 'GOT_CONTRIB':
        {
            return Object.assign({}, state, {contrib:action.contrib});
        }
        case 'GOT_LIKED':
        {
            return Object.assign({}, state, {liked:action.liked});
        }
        case 'OPEN_PROFILE_MENU':
        {
            return Object.assign({}, state, {profileMenuIsOpen:true});
        }
        case 'CLOSE_PROFILE_MENU':
        {
            return Object.assign({}, state, {profileMenuIsOpen:false});
        }
        default:
            return state
    }
};

export default profile