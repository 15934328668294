const INITIAL_STATE =
    {
        screen:'Marketplace',
        creating:false
    };
const home = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_HOME': {
            return INITIAL_STATE;
        }
        case 'SET_SCREEN': {
            return Object.assign({}, state, {screen:action.screen});
        }
        case 'SET_CREATING': {
            return Object.assign({}, state, {creating:action.creating});
        }
        default:
            return state
    }
};

export default home
