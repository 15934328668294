import { connect } from 'react-redux'
import Home from '../components/Home'
import {
    getTransfers, setTagParams
} from '../actions'

const mapStateToProps = (state, props) => {
    return{
        transfers:state.transfers.info
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTransfers:() => {
            dispatch(getTransfers());
        },
        setTagParams:(tag_hash, picc_data, enc, cmac) => {
            dispatch(setTagParams(tag_hash, picc_data, enc, cmac));
        },
    };
};

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

export default HomeContainer
