import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//import PrivateRoute from './components/utils/PrivateRoute'

import HomeContainer from "./containers/HomeContainer";
import TransferContainer from "./containers/TransferContainer";

export default (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<HomeContainer/>} />
            <Route path='/transfer' element={<TransferContainer/>} />
        </Routes>
    </BrowserRouter>
)
