const INITIAL_STATE =
    {
        content: [],
        contentIdx: 0,
        detailsShowing: false,
        maxlols:0,
        refresh:false,
        investDlg:false,
        buyDlg:false,
        investAmount:0,
        selectedContent:null,
        nxPg:''
    };
const content = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_CONTENT':
        {
            return INITIAL_STATE;
        }
        case 'SET_CONTENT':
        {
            return Object.assign({}, state, {content:action.content});
        }
        case 'APPEND_CONTENT':
        {
            return Object.assign({}, state, {content:[...state.content, ...action.content]});
        }
        case 'SET_SELECTED_CONTENT':
        {
            return Object.assign({}, state, {selectedContent:action.content});
        }
        case 'SET_MAX_LOLS':
        {
            return Object.assign({}, state, {maxlols:action.maxlols});
        }
        case 'SET_CONTENT_IDX':
        {
            return Object.assign({}, state, {contentIdx:action.idx});
        }
        case 'SET_INVEST_DLG':
        {
            return Object.assign({}, state, {investDlg:action.state});
        }
        case 'SET_BUY_DLG':
        {
            return Object.assign({}, state, {buyDlg:action.state});
        }
        case 'SET_INVEST_AMOUNT':
        {
            return Object.assign({}, state, {investAmount:action.amount});
        }
        case 'SET_DETAILS_SHOWING':
        {
            return Object.assign({}, state, {detailsShowing:action.showing});
        }
        case 'SET_REFRESH':
        {
            return Object.assign({}, state, {refresh:action.refresh});
        }
        case 'SET_NEXT_PAGE':
        {
            return Object.assign({}, state, {nxPg:action.nxPg});
        }
        default:
            return state
    }
};

export default content
