import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Navigate} from "react-router-dom";

class InitCoin extends React.Component{
    changeType(e){

    }
    render() {
        if (this.props.error) return (<div className='hvcentered'>Could Not Get Position: {this.props.errorMsg}</div>);
        return (
            <div className='hvcentered'>
                {this.props.coinInited && <Navigate to='/transfer'/>}
                <Box>
                    <FormControl>
                        <InputLabel>INITIALIZE COIN</InputLabel>
                        <br/>
                        <Select
                            style={{marginTop:'25px'}}
                            value={10}
                            label="Type"
                            onChange={this.changeType}
                        >
                            <MenuItem value={10}>Photo</MenuItem>
                            <MenuItem value={20}>Photomap</MenuItem>
                            <MenuItem value={30}>Video</MenuItem>
                        </Select>
                        <br/>
                        <TextField label="Initial Message" variant="outlined" />
                        <br/>
                        <Button variant="contained">Select Mosaic</Button>
                        <br/>
                        <Button variant="contained" onClick={(e)=>this.props.initCoin()}>Initialize</Button>
                    </FormControl>
                </Box>
            </div>
        );
    }
}
export default InitCoin
