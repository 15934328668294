const INITIAL_STATE =
    {
        cid: null,
        info: []
    };
const transfers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_CHAIN':
        {
            return INITIAL_STATE;
        }
        case 'SET_CID':
        {
            return Object.assign({}, state, {cid:action.cid});
        }
        case 'SET_TRANSFERS':
        {
            return Object.assign({}, state, {info:action.info});
        }
        default:
            return state
    }
};

export default transfers
