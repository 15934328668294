import { connect } from 'react-redux'
import InitCoin from '../components/InitCoin'
import {
    initCoin
} from '../actions'

const mapStateToProps = (state, props) => {
    return{
        coinInited:state.local.coinInited
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        initCoin:()=>{
            dispatch(initCoin());
        }
    };
};

const InitCoinContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(InitCoin);

export default InitCoinContainer
