const INITIAL_STATE =
    {
        lng:'',
        lat:'',
        label:'',
        text:'',
        image:'',
        video:'',
        facingMode:'user',
        transfered:false
    };
const transfer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_TRANSFER':
        {
            return INITIAL_STATE;
        }
        case 'SET_TRANSFER_LOC':
        {
            return Object.assign({}, state, {lng:action.lng, lat:action.lat});
        }
        case 'SET_TRANSFER_LABEL':
        {
            return Object.assign({}, state, {label:action.label});
        }
        case 'SET_TRANSFER_TEXT':
        {
            return Object.assign({}, state, {text:action.text});
        }
        case 'SET_TRANSFER_IMAGE':
        {
            return Object.assign({}, state, {image:action.image});
        }
        case 'SET_TRANSFER_VIDEO':
        {
            return Object.assign({}, state, {video:action.video});
        }
        case 'SET_TRANSFERED':
        {
            return Object.assign({}, state, {transfered:true});
        }
        case 'SET_POSITION':
        {
            return Object.assign({}, state, {lat:action.lat,lng:action.lng,});
        }
        default:
            return state
    }
};

export default transfer
