import { applyMiddleware, compose, createStore } from 'redux'
import createRootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(preloadedState) {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        createRootReducer(),
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                sagaMiddleware,
            ),
        ),
    );
    sagaMiddleware.run(rootSaga);
    return store
}