import { connect } from 'react-redux'
import App from '../App'
import {init, apiCancelUpload, confirmYes, confirmNo, hideAlert} from '../actions'

const mapStateToProps = (state, props) => {
    return{
        alert:state.app.alert,
        alertConfig:state.app.alertConfig,
        showProgress: state.app.showProgress,
        message:state.app.message,
        indeterminate: state.app.indeterminate,
        percent: state.app.percent,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        init: ()=>{
          dispatch(init());
        },
        uploadCancel: ()=> {
            dispatch(apiCancelUpload())
        },
        dialogYes: () => {
            dispatch(confirmYes())
        },
        dialogNo: () => {
            dispatch(confirmNo())
        },
        dialogDismiss: () => {
            dispatch(hideAlert())
        }
    }
};

const AppContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default AppContainer