const INITIAL_STATE =
    {
        inited:false,
        coinInited:false,
        browserInfo:'',
        cantShare:false,
        orientation:0,
        w:0,
        h:0,
        from:'',
        createDialogShowing:false,
        title:'',
        path:'/',
        jumpTo:'',
        devices:null,
        device:null,
        tag_hash:null,
        picc_data:null,
        enc:null,
        cmac:null
    };
const local = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_LOCAL_STATE': {
            return INITIAL_STATE;
        }
        case 'SET_PATH': {
            return Object.assign({}, state, {path:action.path});
        }
        case 'SET_TAG_PARAMS': {
            return Object.assign({}, state, {tag_hash:action.tag_hash, picc_data:action.picc_data,enc:action.enc, cmac:action.cmac});
        }
        case 'INIT_COMPLETE': {
            return Object.assign({}, state, {inited:true});
        }
        case 'INIT_COIN_COMPLETE': {
            return Object.assign({}, state, {coinInited:true});
        }
        case 'CANT_SHARE':{
            return Object.assign({}, state, {cantShare:true});
        }
        case 'SET_BROWSER_INFO':
        {
            return Object.assign({}, state, {browserInfo:action.info})
        }
        case 'SET_DEVICE_DIMENSIONS':
        {
            return Object.assign({}, state, {orientation:action.orientation, width:action.w, height:action.h})
        }
        case 'SET_FROM':
        {
            return Object.assign({}, state, {from:action.from})
        }
        case 'TITLE_CHANGE':
        {
            return Object.assign({}, state, {title:action.title})
        }
        case 'SET_JUMP_TO':
        {
            return Object.assign({}, state, {jumpTo:action.url})
        }
        case 'SET_CAMERAS':
        {
            return Object.assign({}, state, {cameras:action.cameras})
        }
        case 'SET_CAMERA':
        {
            return Object.assign({}, state, {camera:action.camera})
        }
        default:
            return state
    }
};

export default local
