const INITIAL_STATE =
{
    title:'',
    type:'',
    details:'',
    step:0,
    nid:',',
    stage:'',
    tokenId:'',
    blockInfo:'',
    contractAddress:'',
    networkId:''
};
const create = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_CREATE_STATE': {
            return INITIAL_STATE;
        }
        case 'SET_TITLE': {
            action.title = action.title.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
            return Object.assign({}, state, {title:action.title});
        }
        case 'SET_TYPE': {
            return Object.assign({}, state, {type:action.ctype});
        }
        case 'SET_DETAILS': {
            return Object.assign({}, state, {details:action.details});
        }
        case 'GOT_NFT_ID': {
            return Object.assign({}, state, {nid:action.nid});
        }
        case 'SET_STEP': {
            return Object.assign({}, state, {step:action.step});
        }
        case 'CREATE_NFT_PROGRESS': {
            return Object.assign({}, state, {stage:action.stage});
        }
        case 'SET_TOKEN_ID': {
            return Object.assign({}, state, {tokenId:action.tokenId});
        }
        case 'SET_BLOCK_INFO': {
            return Object.assign({}, state, {blockInfo:action.blockInfo});
        }
        case 'SET_CONTRACT_ADDRESS': {
            return Object.assign({}, state, {contractAddress:action.contractAddress});
        }
        case 'SET_NETWORK_ID': {
            return Object.assign({}, state, {networkId:action.networkId});
        }
        case 'CLEAR_STAGE': {
            return Object.assign({}, state, {stage:''});
        }
        default:
            return state
    }
};

export default create