const INITIAL_STATE =
    {
        vidTrigger: false,
        isPlaying:false,
        pauseRequest:false,
    };
const video = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_VIDEO_STATE': {
            return INITIAL_STATE;
        }
        case 'SET_IS_PLAYING':{
            return Object.assign({}, state, {isPlaying:action.isPlaying});
        }
        case 'VIDEO_PAUSE_REQUEST':{
            return Object.assign({}, state, {pauseRequest:true});
        }
        case 'CLEAR_VIDEO_PAUSE_REQUEST':{
            return Object.assign({}, state, {pauseRequest:false});
        }
        default:
            return state
    }
};

export default video
