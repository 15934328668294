// API
export const apiStart = (info) => {return {type: 'API_START',info:info}};
export const apiSuccess = () => {return {type: 'API_SUCCESS'}};
export const error = (errorMsg) => {return {type: 'API_ERROR', errorMsg: errorMsg}};
export const apiUploadProgress = (percent) => {return {type: 'API_UPLOAD_PROGRESS', percent: percent}};
export const apiCancelUpload = () => {return {type: 'API_CANCEL_UPLOAD'}};
export const apiClear = () => {return {type: 'API_CLEAR'}};
export const getPosition = () => {return {type: 'GET_POSITION'}};
export const setPosition = (lat, lng) => {return {type: 'SET_POSITION', lat:lat, lng:lng}};

//APP
export const setAPIUrl = (url) => {return {type:'SET_API_URL',url:url}};
export const updateProgress = (percent) => {return {type:'UPDATE_PROGRESS',percent:percent}};
export const showProgress = (message, indeterminate) => {return {type:'SHOW_PROGRESS',message:message, indeterminate:indeterminate}};

//UTILS
export const initComplete = () => {return {type: 'INIT_COMPLETE'}}
export const initCoin = () => {return {type: 'INIT_COIN'}}
export const initCoinComplete = () => {return {type: 'INIT_COIN_COMPLETE'}}
export const goBack = () => {return {type: 'GO_BACK'}}
export const setFrom = (from) => {return {type:'SET_FROM',from:from}}
export const setScreenSize = (width, height) => {return {type:'SET_SCREEN_SIZE',width:width, height:height}};
export const setBrowserInfo = (info) => {return {type:'SET_BROWSER_INFO',info:info}};
export const shareLink = (title, text, url) => {return {type: 'SHARE_LINK', title:title, text:text, url:url}};
export const cantShare = () => {return {type: 'CANT_SHARE'}};
export const shareCompleted = () => {return {type: 'SHARE_COMPLETED'}};
export const init = () => {return {type: 'INIT'}};
export const flashMessage = (message) => {return {type: 'FLASH_MESSAGE', message:message}};
export const showFlash = (message) => {return {type:'SHOW_FLASH',message:message}};
export const hideFlash = () => {return {type:'HIDE_FLASH'}};
export const hideAlert = () => {return {type:'HIDE_ALERT'}};
export const withdraw = () => {return {type:'WITHDRAW'}};

//ALERTS
export const clearAlert = (idx) => {return {type: 'CLEAR_ALERT',idx:idx}};

// CONFIRM
export const showConfirmation = (confirmMsg, subText) => {return {type: 'SHOW_CONFIRMATION',confirmMsg:confirmMsg, subText:subText}}
export const hideConfirmation = () => {return {type: 'HIDE_CONFIRMATION'}}
export const confirmYes = () => {return {type: 'CONFIRM_YES'}}
export const confirmNo = () => {return {type: 'CONFIRM_NO'}}

//LOCAL
export const initLocal = () => {return {type:'INIT_LOCAL_STATE'}};
export const setPath = (path) => {return {type:'SET_PATH',path:path}};
export const setJumpTo = (url) => {return {type:'SET_JUMP_TO',url:url}};
export const setDeviceDimensions = (orientation, w, h) => {return {type:'SET_DEVICE_DIMENSIONS',orientation:orientation, w:w, h:h}};
export const setTagParams = (tag_hash, picc_data, enc, cmac) => {return {type:'SET_TAG_PARAMS', tag_hash:tag_hash, picc_data:picc_data, enc:enc, cmac:cmac}};

//HOME
export const setScreen = (screen) => {return {type:'SET_SCREEN',screen:screen}};
export const setCreating = (creating) => {return {type:'SET_CREATING',creating:creating}};

//MEDIA PICKER
export const filePicked = () => {return {type:'FILE_PICKED'}};
export const clearSuccess = () => {return {type:'CLEAR_SUCCESS'}};
export const mediaPickerClear = () => {return {type: 'MEDIA_PICKER_CLEAR'}};
export const mediaPickerAttachmentChanges = (files) => {return {type: 'MEDIA_PICKER_ATTACHMENT_CHANGES', files: files}};
export const mediaPickerShowAttachmentThumbnails = (files) => {return {type: 'MEDIA_PICKER_SHOW_ATTACHMENT_THUMBNAILS', files: files}}

// DELETE
export const deleteComplete = () => {return {type:'SET_DELETE_COMPLETE'}};
export const clearDeleteComplete = () => {return {type:'CLEAR_DELETE_COMPLETE'}};

//VIDEO
export const initVideo = () => {return {type:'INIT_VIDEO_STATE'}};
export const setIsPlaying = (isPlaying) => {return {type:'SET_IS_PLAYING', isPlaying:isPlaying}};
export const pauseVideo = () => {return {type:'VIDEO_PAUSE_REQUEST'}};
export const clearPauseVideo = () => {return {type:'CLEAR_VIDEO_PAUSE_REQUEST'}};

//TRANSFERS
export const getTransfers = () => {return {type:'GET_TRANSFERS'}};
export const setTransfers = (info) => {return {type:'SET_TRANSFERS', info:info}};
export const setCID = (cid) => {return {type:'SET_CID', cid:cid}};
//TRANSFER
export const initTransfer = () => {return {type:'INIT_TRANSFER'}};
export const setTransfered = () => {return {type:'SET_TRANSFERED'}};
export const addTransfer = () => {return {type:'ADD_TRANSFER'}};
export const setTransferLoc = (lng, lat) => {return {type:'SET_TRANSFER_LOC', lng:lng, lat:lat}};
export const setTransferLabel = (label) => {return {type:'SET_TRANSFER_LABEL', label:label}};
export const setTransferText = (text) => {return {type:'SET_TRANSFER_TEXT', text:text}};
export const setTransferImage = (image) => {return {type:'SET_TRANSFER_IMAGE', image:image}};
export const setTransferVideo = (video) => {return {type:'SET_TRANSFER_VIDEO', video:video}};

//MAPIT
export const setOpenDetailsDlg = (state) => {return {type:'SET_OPEN_DETAILS_DLG', state:state}};
export const setDetailsIdx = (didx) => {return {type:'SET_DETAILS_IDX', didx:didx}};
//MOSAIC
export const setMosaicSources = (sources) => {return {type:'SET_SOURCES', sources:sources}};
export const setTappedImage = (tappedImage) => {return {type:'SET_TAPPED_IMAGE', tappedImage:tappedImage}};
export const setOpenMosaicDetailsDlg = (state) => {return {type:'SET_OPEN_MOSAIC_DETAILS_DLG', state:state}};
