import React from 'react';
import Button from '@mui/material/Button';
import audioIcon from '../img/audioicon.png'
import MosaicContainer from "../containers/MosaicContainer";
import {Navigate} from "react-router-dom";

class Transfer extends React.Component{
    componentDidMount() {
        this.props.init();
        this.props.getPosition();
    }
    showThumbs(){
        return this.props.attachmentthumbinfo.map((attachmentInfo,idx) => {
            if (attachmentInfo.type.match('image'))
            {
                return (
                    <img key={idx} className="attachment-thumbs" src={attachmentInfo.image} alt="" onClick={(evt)=>this.ignoreClick(evt)}/>
                )
            }
            else if (attachmentInfo.type.match('audio'))
            {
                return (
                    <video className="attachment-thumbs" key={idx} controls poster={audioIcon}>
                        <source src={attachmentInfo.url} type="video/mp4"/>
                    </video>
                )
            }
            else
            {
                return (
                    <video className="attachment-thumbs" key={idx} controls>
                        <source src={attachmentInfo.url} type="video/mp4"/>
                    </video>
                )
            }
        })
    }
    pickImage(){
        var chromeMultipleBugWorkaround = {}
        if (this.props.browserInfo.browser !== 'gcm') chromeMultipleBugWorkaround = {multiple:'multiple'}
        return (<div style={{margin:'15px'}}>
            <Button className="media-picker-img" variant="contained" color="primary">
                <label className="media-picker-img" htmlFor="message-file-picker">
                    {(this.props.attachmentthumbinfo && this.props.attachmentthumbinfo.length > 0) ?
                        <>Change Image</>
                        :
                        <>Pick An Image</>}
                </label>
            </Button>
            <input ref={(input) => { this.fileInput = input; }} className="media-picker-file" type="file" id="message-file-picker" accept={'image/*'} onChange={(evt) => (this.props.onFilePicked(evt))} {...chromeMultipleBugWorkaround}/>
        </div>)
    }
    render() {
        if (this.props.error) return (<div className='hvcentered'>Could Not Get Position: {this.props.errorMsg}</div>);
        return (
            <div className='fullscreen'>
                {this.props.transfered ? <>
                        <br/> Added.  Go pass it on.<br/>
                        <MosaicContainer/>
                    </>
                    :
                    <>
                        {this.props.attachmentthumbinfo && this.props.attachmentthumbinfo.length > 0 &&
                        <Button onClick={(e)=>this.props.addTransfer()} style={{margin:'15px'}} className="media-picker-img" variant="contained" color="primary">
                            <label className="media-picker-img">
                                Submit
                            </label>
                        </Button>
                        }
                        {this.showThumbs()}
                        {this.pickImage()}
                    </>
                }
            </div>
        );
    }
}
export default Transfer
