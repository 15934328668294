import React from 'react';
import './App.css';
import routes from './routes'
import Loader from 'react-loader-advanced'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#e3b418"
    },
    secondary: {
      main: "#ffffff",
    }
  },
  typography: {
    body1: {
      fontFamily: "Comic Sans"
    },
    button: {
      textTransform: "none"
    }
  },
  colorSecondary: {
    '&$checked': {
      color: 'green',
    },
  }
});

class App extends React.Component{
  componentDidMount() {
    this.props.init();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {

  }
  render(){
    var spinner;
    var actions
    if (this.props.alert)
    {
      if (this.props.alertConfig.type === 'error' || this.props.alertConfig.type === 'message') {
        actions = [
          <Button size='large' key='1' variant='contained' color='primary' onMouseUp={this.props.dialogDismiss}>Ok</Button>
        ]
      }
      else if (this.props.alertConfig.type === 'flash')
      {
        actions = null
      }
      else if (this.props.alertConfig.type === 'confirm')
      {
        actions = [
          <Button size='large' key='1' variant='contained' component="span" onClick={this.props.dialogNo}>No</Button>,
          <Button size='large' key='2' variant='contained' color='primary' onClick={this.props.dialogYes} autoFocus>Yes</Button>
        ]
      }
    }
    if (this.props.showProgress && !this.props.indeterminate)
    {
      spinner = (
          <div style={{marginTop:'25px'}}>
            <CircularProgress variant="determinate" value={Number(this.props.percent)} />
            <p/>
            <div style={{marginBottom:'15px',paddingBottom:'15px'}}>{'Uploading: ' + this.props.percent + '% Completed'}</div>
            <p/>
            <Button variant="contained" color="primary" onClick={this.props.uploadCancel}>
              Cancel
            </Button>
          </div>
      )
    }
    else
    {
      spinner = (
          <div style={{marginTop:'25px'}}>
            <CircularProgress/>
            <p/>
            {this.props.message}
          </div>
      )
    }
    return (
        <div className="App">
          <ThemeProvider theme={theme}>
            <div className="main">
              <Dialog open={this.props.alert} onClose={this.props.dialogDismiss}>
                <DialogTitle>{this.props.alertConfig.title}</DialogTitle>
                <DialogContent style={{textAlign:'center'}}>
                  <DialogContentText>
                    {this.props.alertConfig.subtext}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  {actions}
                </DialogActions>
              </Dialog>
              <Loader foregroundStyle={{color: 'black'}} backgroundStyle={{backgroundColor: 'rgba(0,0,0,0)'}} show={this.props.showProgress} message={spinner} className='loader' hideContentOnLoad={true}>
                {routes}
              </Loader>
            </div>
          </ThemeProvider>
        </div>
    );
  }
}

export default App;
