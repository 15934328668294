const INITIAL_STATE = {
    alert:false,
    alertConfig:{title:'',subtext:''},
    message:'',
    indeterminate: true,
    percent: 0,
    showProgress:false,
    width:0,
    height:0
};
const app = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'API_ERROR':
        {
            if (!action.error) return INITIAL_STATE
            let msg= ''
            if (action.error.statusText) msg = action.error.statusText
            else if (action.error.message) msg = action.error.message

            let alertConfig = {type:'error', title:'Error', subtext:msg}
            return Object.assign({}, state, {alert:true, alertConfig:alertConfig, showProgress:false, progress:0})
        }
        case 'SHOW_MESSAGE':
        {
            let alertConfig = {type:'message', title:action.title, subtext:action.subtext}
            return Object.assign({}, state, {alert:true, alertConfig:alertConfig})
        }
        case 'SHOW_FLASH':
        {
            let alertConfig = {type:'flash', title:'', subtext:action.message}
            return Object.assign({}, state, {alert:true, alertConfig:alertConfig})
        }
        case 'HIDE_ALERT':
        case 'HIDE_FLASH':
        {
            return Object.assign({}, state, {alert:false})
        }
        case 'SHOW_PROGRESS':
        {
            return Object.assign({}, state, {showProgress:true, message:action.message, indeterminate:action.indeterminate})
        }
        case 'UPDATE_PROGRESS':
        {
            return Object.assign({}, state, {percent:action.percent})
        }
        case 'SHOW_CONFIRMATION':
        {
            let alertConfig = {type:'confirm', title:action.confirmMsg, subtext:action.subText}
            return Object.assign({}, state, {alert:true, alertConfig:alertConfig})
        }
        case 'API_SUCCESS':
        case 'API_CANCEL_UPLOAD':
        case 'HIDE_CONFIRMATION':
        {
            return Object.assign({}, state, {showProgress:false, alert:false})
        }
        case 'FORGOT_SUCCESS':
        {
            let alertConfig = {type:'message', title:'Password Recovery', subtext:'A password recovery link has been sent.'}
            return Object.assign({}, state, {alert:true, alertConfig:alertConfig})
        }
        /*case 'SET_BROWSER_INFO':
        {
            if (!action.info.isMobile)
            {
                //    let alertConfig = {type:'message', title:'Deedles is currently designed for mobile.', subtext:'You may proceed but you may encounter issues.'}
                //    return Object.assign({}, state, {alert:true, alertConfig:alertConfig})
            }
            else if (action.info.browser !== 'safari' && action.info.browser !== 'chrome' && action.info.browser !== 'gcm' && action.info.browser !== 'gc')
            {
                let alertConfig = {type:'message', title:'You are using an unsupported browser.  You can proceed but you may encounter issues.', subtext:'Detected Browser:'+action.info.browser}
                return Object.assign({}, state, {alert:true, alertConfig:alertConfig})
            }
            return state
        }*/
        case 'SET_SCREEN_SIZE':
        {
            return Object.assign({}, state, {width:action.width, height:action.height})
        }
        default:
            return state
    }
};

export default app
