const INITIAL_STATE =
    {
        promoteNativeApps:false,
        preview:true,
        review:true
    };
const link = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_LINK':
        case 'ADD_TO_CEEN_FAILED':
        {
            return INITIAL_STATE;
        }
        case 'SHOW_PROMOTE_NATIVE_APPS':
        {
            return Object.assign({}, state, {promoteNativeApps:true});
        }
        case 'SET_PREVIEW':
        {
            return Object.assign({}, state, {preview:action.preview});
        }
        case 'SET_REVIEW':
        {
            return Object.assign({}, state, {review:action.review});
        }
        default:
            return state
    }
};

export default link