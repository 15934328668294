const INITIAL_STATE =
    {
        cid:'',
        content:{}
    };
const view = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_VIEW': {
            return INITIAL_STATE;
        }
        case 'SET_VIEW_CID':{
            return Object.assign({}, state, {cid:action.cid});
        }
        case 'SET_VIEW_CONTENT':{
            return Object.assign({}, state, {content:action.content});
        }
        default:
            return state
    }
};

export default view
