const INITIAL_STATE =
    {
        activeTab:0
    };
const library = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_LIBRARY_STATE': {
            return INITIAL_STATE;
        }
        case 'SET_ACTIVE_TAB':
        {
            return Object.assign({}, state, {activeTab:action.tab});
        }
        default:
            return state
    }
};

export default library