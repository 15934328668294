import { combineReducers } from 'redux'
import local from './local'
import mediapicker from './mediapicker'
import home from './home'
import api from './api'
import app from './app'
import link from './link'
import manage from './manage'
import view from './view'
import user from './user'
import video from './video'
import profile from './profile'
import alerts from './alerts'
import library from './library'
import create from './create'
import content from './content'
import connect from './connect'
import transfers from './transfers'
import transfer from './transfer'
import mapit from './mapit'
import mosaic from './mosaic'

var createRootReducer = () => combineReducers({
    api,
    local,
    mediapicker,
    home,
    link,
    app,
    manage,
    view,
    user,
    video,
    profile,
    alerts,
    library,
    create,
    content,
    connect,
    transfers,
    transfer,
    mapit,
    mosaic
});

export default createRootReducer;
