const INITIAL_STATE =
    {
        uid:null,
    };

const user = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'LOGOUT':
        case 'INIT_USER_STATE': {
            return INITIAL_STATE;
        }
        case 'SET_UID':
        {
            return Object.assign({}, state, {uid: action.uid});
        }
        default:
            return state
    }
};

export default user
