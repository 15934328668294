const INITIAL_STATE =
    {

    };
const alerts = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_USER_STATE': {
            return INITIAL_STATE;
        }
        default:
            return state
    }
};

export default alerts