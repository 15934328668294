import axios from 'axios'
import {put, take} from 'redux-saga/effects'
import * as actions from '../actions'
import { eventChannel, END } from 'redux-saga'

let progressEmitter;
var CancelToken = axios.CancelToken;
var uploadCanceler;

const pimagic = axios.create({
    baseURL: process.env.REACT_APP_PIMAGIC_API,
    //headers: {"Content-Type":"application/json"}
});
const pimagicMedia = axios.create({
    baseURL: process.env.REACT_APP_MEDIA_HOME
});
export function* sendToken(token, blockv_id) {
    try
    {
        var data = yield pimagic.post('get_access_token', {public_token:token,blockv_id:blockv_id}).then(function (response) {
            if (response.data.error)
            {
                throw new Error(response.data.error)
            }
            return response.data;
        });
        yield put(actions.apiSuccess('sentToken'));
        return data;
    }
    catch(error)
    {
        yield put(actions.error(error));
        return {error:error};
    }
}
export function* getTransfers(tag_hash, picc_data, enc, cmac) {
    try
    {
        var data = yield pimagic.get('pimagic-get-transfers?tag_hash='+tag_hash+'&picc_data='+picc_data+'&enc='+enc+'&cmac='+cmac).then(function (response) {
            if (response.data.error)
            {
                throw new Error(response.data.error)
            }
            return response.data;
        });
        return data;
    }
    catch(error)
    {
        yield put(actions.error(error))
        return {error:error};
    }
}
export function* initCoin(tag_hash, picc_data, enc, cmac) {
    try
    {
        return;
        var data = yield pimagic.get('pimagic-init-coin?tag_hash='+tag_hash+'&picc_data='+picc_data+'&enc='+enc+'&cmac='+cmac).then(function (response) {
            if (response.data.error)
            {
                throw new Error(response.data.error)
            }
            return response.data;
        });
        return data;
    }
    catch(error)
    {
        yield put(actions.error(error))
        return {error:error};
    }
}
export function* addTransfer(lng, lat, label, text, mediaType, mediaFile, facingMode, tag_hash, picc_data, enc, cmac) {
        var params = {lng:lng, lat:lat, label:label, text:text, mediaType: mediaType, mediaFile:mediaFile, facingMode:facingMode, tag_hash:tag_hash, picc_data:picc_data, enc:enc, cmac:cmac};
        var response = yield pimagic.post('pimagic-add-transfer', params);
        console.log('addTransfer:'+JSON.stringify(response));
        if (response.data.error)
        {
            throw new Error(response.data.error)
        }
        return response.data;
}
export function* pimagicUserExist(blockv_id) {
    try
    {
        var data = yield pimagic.get('user_exist?blockv_id='+blockv_id).then(function (response) {
            if (response.data.error)
            {
                throw new Error(response.data.error)
            }
            return response.data;
        });
        return data;
    }
    catch(error)
    {
        yield put(actions.error(error))
        return {error:error};
    }
}

export function* uploadFile(file, tag_hash, picc_data, enc, cmac){
    var contentType = 'application/octet-stream';
    try {
        yield put(actions.showProgress("Uploading...",false,0));
        var urlResult =  yield pimagic.get(encodeURI('pimagic-get-upload-url?ct='+contentType));
        if (urlResult.err)
        {
            return urlResult;
        }
    }catch(err){
        return {err:true, errorMsg:err.message};
    }

    try{
        var chan = uploadEmitter(urlResult.data.url, file, contentType);
        yield progressListener(chan);
        return {err:false, key:urlResult.data.key};
    }catch(err){
        return {err:true, errorMsg:err.message};
    }
}

function uploadEmitter(url, file, contentType) {
    return eventChannel(emit => {
        progressEmitter = emit;
        var ct = new CancelToken(function executor(c) {uploadCanceler = c});
        pimagicMedia.put(url, file, {headers: {"Content-Type": contentType}, onUploadProgress: onUploadProgress, cancelToken: ct}).then(function (response) {
            onUploadProgress({status:response.status,statusText:response.statusText});
            return response
        });

        return () => {

        }
    })
}
function onUploadProgress(event){
    console.log("EVENT:"+JSON.stringify(event));
    var percent = Number((event.loaded/event.total)*100).toFixed(2)
    if (event.status) {
        if (event.status === 200)
        {
            progressEmitter(END)
        }else{
            progressEmitter(new Error('Upload Err:'+event.status+' '+event.statusText));
        }
    }
    else
        progressEmitter(percent)
}
export function cancelUpload(){
    uploadCanceler();
}
function* progressListener(chan) {
    try
    {
        while (true) {
            const data = yield take(chan);
            yield put(actions.updateProgress(data));
        }
    }
    finally {
        console.log("UPLOADED THE FILE")
    }
}
export function* addTransferStatus(cid, ts){
    try {
        var url = 'pimagic-add-transfer-status?cid='+cid+'&ts='+ts;
        var urlResult =  yield pimagic.get(url);
        if (urlResult.data.err === 'true')
        {
            return {err:true, errorMsg:urlResult.data.errorMsg};
        }else{
            return {err:false, data:urlResult.data};
        }
    }catch(err){
        return {err:true, errorMsg:err.message};
    }
}
