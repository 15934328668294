import { fork, put, takeEvery, select, call, all } from 'redux-saga/effects'
import * as actions from '../actions'
import { isIOS, isAndroid } from 'mobile-device-detect';
import EXIF from 'exif-js';
import * as pimagicAPI from './pimagicapi';

// UTIL
function getBrowserInfo() {
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera
        || navigator.userAgent.indexOf(' OPR/') >= 0;
    const isFirefox = typeof InstallTrigger !== 'undefined';
    //const isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
    //const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const isEdge = !(isIE) && !!window.StyleMedia;
    const isChrome = !!window.chrome && !!window.chrome.webstore;
    //const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    var ua = navigator.userAgent;
    var brow = /Edge\/\d+/.test(ua) ? 'ed' : /MSIE 9/.test(ua) ? 'ie9' : /MSIE 10/.test(ua) ? 'ie10' : /MSIE 11/.test(ua) ? 'ie11' : /MSIE\s\d/.test(ua) ? 'ie?' : /rv:11/.test(ua) ? 'ie11' : /Firefox\W\d/.test(ua) ? 'ff' : /Chrome\W\d/.test(ua) ? 'gc' : /CriOS\W\d/.test(ua) ? 'gcm' : /Chromium\W\d/.test(ua) ? 'oc' : /\bSafari\W\d/.test(ua) ? 'sa' : /\bOpera\W\d/.test(ua) ? 'op' : /\bOPR\W\d/i.test(ua) ? 'op' : typeof MSPointerEvent !== 'undefined' ? 'ie?' : '';
    const isSafari = (brow === 'sa');

    const isBlink = (isChrome || isOpera) && !!window.CSS;

    let browser;

    if (isOpera) {
        browser = 'opera';
    } else if (isFirefox) {
        browser = 'firefox';
    } else if (isSafari) {
        browser = 'safari';
        //var version = ua.split('Version/')[1];
    } else if (isIE) {
        browser = 'ie';
    } else if (isEdge) {
        browser = 'edge';
    } else if (isChrome) {
        browser = 'chrome';
    } else if (isBlink) {
        browser = 'blink';
    } else {
        if  ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1))
        {
            browser = 'fb';
        }else browser = brow;
    }
    //   var isMobile = false; //initiate as false
// device detection
//    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
//        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(navigator.userAgent.substr(0,4))) isMobile = true;

    var isMobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    var lang = browserLocale();
    return {browser:browser,isMobile:isMobile, lang:lang, isIOS:isIOS, isAndroid:isAndroid }
}
function browserLocale () {
    var lang;

    if (navigator.languages && navigator.languages.length) {
        // latest versions of Chrome and Firefox set this correctly
        lang = navigator.languages[0]
    } else if (navigator.userLanguage) {
        // IE only
        lang = navigator.userLanguage
    } else {
        // latest versions of Chrome, Firefox, and Safari set this correctly
        lang = navigator.language
    }
    if (lang === null)
    {
        lang = 'en'
    } else {
        //IGNORE COUNTRY SPECIFIC CODE FOR NOW AND ONLY USE LANGUAGE
        // Or else someone who has their setting as english will not see UK english or US english...
        lang = lang.substr(0,2)
    }
    return lang
}
const backCameraKeywords = [
    "rear",
    "back",
    "rück",
    "arrière",
    "trasera",
    "trás",
    "traseira",
    "posteriore",
    "后面",
    "後面",
    "背面",
    "后置", // alternative
    "後置", // alternative
    "背置", // alternative
    "задней",
    "الخلفية",
    "후",
    "arka",
    "achterzijde",
    "หลัง",
    "baksidan",
    "bagside",
    "sau",
    "bak",
    "tylny",
    "takakamera",
    "belakang",
    "אחורית",
    "πίσω",
    "spate",
    "hátsó",
    "zadní",
    "darrere",
    "zadná",
    "задня",
    "stražnja",
    "belakang",
    "बैक"
];
function isBackCameraLabel( label )
{
    const lowercaseLabel = label.toLowerCase();

    return backCameraKeywords.some( keyword => lowercaseLabel.includes( keyword ) );
}
function* getCameras(){
    var devices = [];
    if (typeof navigator.enumerateDevices === "function")
    {
        devices = yield navigator.enumerateDevices();
    } else if (typeof navigator.mediaDevices === "object" && typeof navigator.mediaDevices.enumerateDevices === "function")
    {
        devices = yield navigator.mediaDevices.enumerateDevices();
    }
    var cameras = [];
    for ( var didx = 0; didx < devices.length ; didx++ )
    {
        var device = devices[didx];
        if (device.kind === "videoinput")
        {
            if ( isBackCameraLabel( device.label ) )
            {
                cameras.push({deviceId:device.deviceId, back:true});
            }
            else
            {
                cameras.push({deviceId:device.deviceId, back:false});
            }
        }
    }
    return cameras;
}
function* init() {
    //let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    var browserInfo = getBrowserInfo();
    yield put(actions.setBrowserInfo(browserInfo));
    if (navigator.share === undefined) {
        yield put(actions.cantShare());
    }

    var apiUrl = process.env.REACT_APP_HOME;
    yield put(actions.setAPIUrl(apiUrl));

    //var cameras = yield getCameras();
    //cameras.forEach(function(camera) {console.log("camera:"+JSON.stringify(camera));});
    //yield put(actions.setCameras(cameras));
    //yield put(actions.setCamera(0));

    const angle = window.screen.orientation ? window.screen.orientation.angle : window.orientation;
    const visibleHeight = window.innerHeight;
    const visibleWidth = window.innerWidth;
    yield put(actions.setDeviceDimensions(angle, visibleWidth, visibleHeight));
}
function* cancelUpload(){
   // cryptickleAPI.cancelUpload();
}
function showAThumbnail(attachment) {
    var reader = new FileReader();
    if (attachment.type.match('image'))
    {
        return new Promise((resolve, reject) => {
            reader.onload = function (e) {
                resolve({type:attachment.type, image:e.target.result})
            }
            reader.readAsDataURL(attachment)
        })
    }
    else
    {
        return new Promise((resolve, reject) => {
            reader.onload = function (e) {
                //var blob = new Blob([e.target.result], {type: attachment.type})
                var url = URL.createObjectURL(attachment)
                resolve({type:attachment.type, url:url})
            }
            reader.onerror = function(e) {
                alert(e.target.error.code)
            }
            reader.readAsDataURL(attachment)
        })
    }
}
function getVideoInfo(file) {
    return new Promise(resolve => {
        var video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = ()=> {
            window.URL.revokeObjectURL(video.src);
            resolve({err:false,video:video});
        }
        video.onerror = (e)=>{
            console.log("Couldn't get video metadata");
            resolve({err:true});
        }
        video.src = URL.createObjectURL(file);
    });
}
function* mediaPickerAttachmentChanges() {
    var attachments =  yield select((state) => {return state.mediapicker.attachments})
    var files = [];
    for (var i = 0; i < attachments.length; i++) {
        var fileInfo = yield showAThumbnail(attachments[i])
        var orientation = yield getExifData(attachments[i])
        fileInfo.orientation = orientation
        files.push(fileInfo)
    }
    yield put(actions.mediaPickerShowAttachmentThumbnails(files))
}
function* mediaPickerClear(){

}
function getExifData(attachment){
    return new Promise(function (resolve, reject) {
        EXIF.getData(attachment,function(){
            resolve(this.exifdata.Orientation)
        });
    })
}
function* waitTillMediaProcessingComplete(cid, ts, waitMsg) {
    let status, jobStatus, item;
    while (true)
    {
        jobStatus = yield pimagicAPI.addTransferStatus(cid, ts);
        status = jobStatus.data.status;
        console.log("status:"+status);
        if (jobStatus.err === 'true' || (status !== "processing" && status !== "no shots" && status !== "not found")) break;
        yield delay(2500);
        yield put(actions.showProgress(waitMsg, true));
        console.log('Waiting for Media Processing')
    }
    if (status === 'completed') item = jobStatus.data.item;
    return {err:false, status:status, item:item};
}
function* getTransfers(action) {
    var tag_hash =  yield select((state) => {return state.local.tag_hash});
    var picc_data =  yield select((state) => {return state.local.picc_data});
    var enc =  yield select((state) => {return state.local.enc});
    var cmac =  yield select((state) => {return state.local.cmac});
    var getTransfersResult = yield pimagicAPI.getTransfers(tag_hash, picc_data, enc, cmac);
    if (getTransfersResult.err)
    {
        yield put(actions.error("ERROR: Couldn't getTransfers"));
    }else{
        console.log('getTransfersResult:'+JSON.stringify(getTransfersResult));
        yield put(actions.setCID(getTransfersResult.cid));
        yield put(actions.setTransfers(getTransfersResult.content));
    }
}
function* initCoin(action) {
    yield put(actions.initCoinComplete());
    return;
    var tag_hash =  yield select((state) => {return state.local.tag_hash});
    var picc_data =  yield select((state) => {return state.local.picc_data});
    var enc =  yield select((state) => {return state.local.enc});
    var cmac =  yield select((state) => {return state.local.cmac});
    var initCoinResult = yield pimagicAPI.initCoin(tag_hash, picc_data, enc, cmac);
    if (initCoinResult.err)
    {
        yield put(actions.error("ERROR: Couldn't initCoin"));
    }else{
        yield put(actions.initCoinComplete());
    }
}
function* addTransfer(action) {
    var cid =  yield select((state) => {return state.transfers.cid});
    var transfer =  yield select((state) => {return state.transfer});
    var attachments =  yield select((state) => {return state.mediapicker.attachments});
    var tag_hash =  yield select((state) => {return state.local.tag_hash});
    var picc_data =  yield select((state) => {return state.local.picc_data});
    var enc =  yield select((state) => {return state.local.enc});
    var cmac =  yield select((state) => {return state.local.cmac});

    var lng = transfer.lng;
    var lat = transfer.lat;
    var label = transfer.label;
    var text = transfer.text;
    var facingMode = transfer.facingMode;
    //var image = transfer.image;
    //var video = transfer.video;
    var mediaType, mediaFile;

    if (attachments.length > 0)
    {
        var attachment = attachments[0];
        attachment.url = URL.createObjectURL(attachment);
        var uploadFileResult = yield pimagicAPI.uploadFile(attachment, tag_hash, picc_data, enc, cmac);
        if (uploadFileResult.err)
        {
            yield put(actions.error(uploadFileResult.errorMsg));
            return;
        }
        console.log('UPLOADED RESULT:'+JSON.stringify(uploadFileResult));
        yield put(actions.apiSuccess());
        mediaType = attachment.type;
        mediaFile = uploadFileResult.key;
    }
    yield put(actions.showProgress('Processing Media', true));
    var addTransferResult = yield pimagicAPI.addTransfer(lat, lng, label, text, mediaType, mediaFile, facingMode, tag_hash, picc_data, enc, cmac);
    if (addTransferResult.err)
    {
        yield put(actions.error(addTransferResult.errorMsg));
        return;
    }
    var ts = addTransferResult.ts;
    var jobResult = yield waitTillMediaProcessingComplete(cid, ts, 'Building Chain');
    console.log("waiting to complete processing");
    if (jobResult.status !== 'completed')
    {
        console.log('jobResult:'+jobResult);
        yield put(actions.error('ERROR: Getting jobResult:'+JSON.stringify(jobResult)));
    }else{
        console.log("All Worked");
        yield put(actions.setTransferImage(mediaFile));
        yield put(actions.setTransfered());
        yield put(actions.apiSuccess());
    }
}
function* shareLink(action) {
    try {
        yield navigator.share({title:action.title, text:action.text, url:action.url});
        yield put(actions.shareCompleted());
        console.log('Successfully sent share');
    } catch (error) {
        console.log('Error sharing: ' + error);
    }
}
function getPositionProm(options){
    try{
        return new Promise((resolve, reject) =>
            {
                var blah = navigator.geolocation.getCurrentPosition(resolve, ()=>{resolve(false);}, options);
            }
        );
    }catch(err){
        console.error("GEOERR:");
    }
}
function* getPosition() {
    if (navigator.geolocation) {
        try {
            const position = yield getPositionProm({enableHighAccuracy: false});
            if (position)
            {
                yield put(actions.setPosition(position.coords.longitude, position.coords.latitude));
            }else{
                yield put(actions.error('Location Access Denied'));
            }
        } catch (err) {
            console.error(err.message);
            yield put(actions.error(err.message()));
        }
    }else{
        console.log("ERROR GETTING POSITION");
        yield put(actions.error('Location Disabled'));
    }
}
function* browseBack() {
   // yield put(goBack());
}
function* flashMessage(action) {
    yield put(actions.showFlash(action.message));
    yield call(delay, 1500);
    yield put(actions.hideFlash());
}
function delay(millis) {
    return new Promise(resolve => {
        setTimeout(() => resolve(true), millis)
    });
}
//HOME
//MEDIA PICKER
function* watchGoBack() {
    yield takeEvery('GO_BACK', browseBack)
}
//MEDIA PICKER
function* watchMediaPickerAttachmentChanges() {
    yield takeEvery('MEDIA_PICKER_ATTACHMENT_CHANGES',mediaPickerAttachmentChanges)
}
function* watchMediaPickerClear() {
    yield takeEvery('MEDIA_PICKER_CLEAR',mediaPickerClear)
}
function* watchCancelUpload() {
    yield takeEvery('API_CANCEL_UPLOAD',cancelUpload)
}
function* watchShareLink() {
    yield takeEvery('SHARE_LINK',shareLink);
}
function* watchInit() {
    yield takeEvery('INIT',init);
}
function* watchFlashMessage() {
    yield takeEvery('FLASH_MESSAGE', flashMessage);
}
function* watchGetTransfers() {
    yield takeEvery('GET_TRANSFERS', getTransfers);
}
function* watchAddTransfer() {
    yield takeEvery('ADD_TRANSFER', addTransfer);
}
function* watchGetPosition() {
    yield takeEvery('GET_POSITION', getPosition);
}
function* watchInitCoin() {
    yield takeEvery('INIT_COIN', initCoin);
}
export default function* rootSaga() {
    yield all([
        //UTILS
        fork(watchGoBack),
        fork(watchShareLink),
        fork(watchInit),
        fork(watchFlashMessage),
        fork(watchGetPosition),
        //COIN
        fork(watchInitCoin),
        //MEDIA PICKER
        fork(watchCancelUpload),
        fork(watchMediaPickerAttachmentChanges),
        fork(watchMediaPickerClear),
        //Transfers
        fork(watchGetTransfers),
        fork(watchAddTransfer),
    ])
}
