import React, { Component } from 'react';
import { GoogleMap, LoadScript, Polyline, Marker } from '@react-google-maps/api';
import Dialog from '@mui/material/Dialog';

var mediaURL = process.env.REACT_APP_MEDIA_HOME;
const containerStyle = {
    width: '100%',
    height: '100%'
};

const mapOptions = {
    disableDefaultUI: true,
};
const polylineOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 3,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 5,
    zIndex: 1
};
class MapIt extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("this.props.transfers:"+JSON.stringify(this.props.transfers));
    }
    faceClicked(didx){
        console.log("CLICKED didx:"+didx);
        console.log("CLICKED mediaFile:"+this.props.transfers[didx].mediaFile);
        console.log("CLICKED:"+JSON.stringify(this.props.transfers[didx]));
        this.props.setDetailsIdx(didx);
        this.props.setOpenDetailsDlg(true);
    }
    handleCloseDetails(){
        this.props.setOpenDetailsDlg(false);
    }
    render() {
        return (
            <LoadScript googleMapsApiKey="AIzaSyDk4Tlqht4m_24b5Ymots-lQm435P6HUIM">
                {this.props.transfers.length > 0 &&
                    <>
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={this.props.transfers[this.props.transfers.length-1]}
                            zoom={15}
                            disableDefaultUI={true}
                            options={mapOptions}
                        >
                            <Polyline
                                path={this.props.transfers}
                                options={polylineOptions}
                            />
                            {this.props.transfers.map((marker, didx) => {
                                //console.log("INDX:"+didx+" marker.lat:"+marker.lat+" marker.lng:"+marker.lng);
                                return (
                                    <Marker key={didx} position={marker} icon={mediaURL+marker.mediaFile+"-xsm.png"} onClick={(e)=>this.faceClicked(didx)}/>
                                )
                            })}
                        </GoogleMap>
                        <Dialog fullScreen open={this.props.openDetailsDlg} onClose={(e)=>this.handleCloseDetails()} onClick={(e)=>this.handleCloseDetails()}>
                            <>
                                {this.props.transfers && this.props.transfers.length > 0 &&
                                    <img className='hcentered' alt='details' src={mediaURL + this.props.transfers[this.props.didx].mediaFile + "-out.png"}/>
                                }
                            </>
                        </Dialog>
                    </>
                }
            </LoadScript>
        )
    }
}
export default MapIt
