import { connect } from 'react-redux'
import Mosaic from '../components/Mosaic'
import {
    setMosaicSources, setTappedImage, setOpenMosaicDetailsDlg
} from '../actions'

const mapStateToProps = (state, props) => {
    return{
        cid:state.transfers.cid,
        screenWidth:state.local.width,
        screenHeight:state.local.height,
        transfers:state.transfers.info,
        sources:state.mosaic.sources,
        image:state.transfer.image,
        tappedImage:state.mosaic.tappedImage,
        openMosaicDetailsDlg:state.mosaic.openMosaicDetailsDlg
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMosaicSources:(sources) => {
            dispatch(setMosaicSources(sources));
        },
        setTappedImage:(tappedImage) => {
            dispatch(setTappedImage(tappedImage));
        },
        setOpenMosaicDetailsDlg:(state) => {
            dispatch(setOpenMosaicDetailsDlg(state));
        },
    };
};

const MosaicContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Mosaic);

export default MosaicContainer
