const INITIAL_STATE =
    {
        web3:null,
        connected:false,
        connect:false,
        sign:false,
        signed:false,
        signature:null,
        sessionID:null
    };
const connect = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_CONNECT':
        {
            return INITIAL_STATE;
        }
        case 'SET_WEB3':
        {
            return Object.assign({}, state, {web3: action.web3});
        }
        case 'SET_SIGN':
        {
            return Object.assign({}, state, {sign: action.sign});
        }
        case 'SET_SIGNATURE':
        {
            return Object.assign({}, state, {signature: action.signature});
        }
        case 'SET_CONNECT':
        {
            return Object.assign({}, state, {connect: action.connect});
        }
        case 'SET_CONNECTED':
        {
            return Object.assign({}, state, {connected: action.connected});
        }
        case 'SET_SIGNED':
        {
            return Object.assign({}, state, {signed: action.signed});
        }
        case 'SET_SESSIONID':
        {
            return Object.assign({}, state, {sessionID: action.sessionID});
        }
        default:
            return state
    }
};

export default connect
