const INITIAL_STATE =
    {
        apiUrl:'',
        error:false,
        errorMsg:'',
        canceled:false
    };
const api = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_API_URL':
        {
            return Object.assign({}, state, {apiUrl:action.url})
        }
        case 'API_ERROR':
        {
            return Object.assign({}, state, {error:true, errorMsg:action.errorMsg})
        }
        case 'API_CLEAR':
        {
            return Object.assign({}, state, {canceled:false})
        }
        case 'API_CANCEL_UPLOAD':
        {
            return Object.assign({}, state, {error:false, errorMsg:'', canceled:true})
        }
        case 'GOTO_URL':
        case 'GO_BACK':
        case 'API_SUCCESS':
        case 'FILE_PICKED':
        case 'SIGN_OUT':
        {
            return Object.assign({}, state, {error:false, errorMsg:''})
        }
        default:
            return state
    }
};

export default api