const INITIAL_STATE =
    {
        sources:[],
        tappedImage:null,
        openMosaicDetailsDlg:false
    };
const mosaic = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_SOURCES': {
            return INITIAL_STATE;
        }
        case 'SET_SOURCES':
        {
            return Object.assign({}, state, {sources:action.sources});
        }
        case 'SET_TAPPED_IMAGE':
        {
            return Object.assign({}, state, {tappedImage:action.tappedImage});
        }
        case 'SET_OPEN_MOSAIC_DETAILS_DLG':
        {
            return Object.assign({}, state, {openMosaicDetailsDlg:action.state});
        }
        default:
            return state
    }
};

export default mosaic
