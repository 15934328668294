const INITIAL_STATE = {
    attachments:[],
    error:false,
    errorMsg:'',
    attachmentthumbinfo:[],
};
const mediapicker = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_TO_CEEN_ERROR':
        case 'API_CANCEL_UPLOAD':
        case 'MEDIA_PICKER_CLEAR':
        {
            return INITIAL_STATE;
        }
        case 'MEDIA_PICKER_ATTACHMENT_CHANGES':
        {
            var currentAttachmentCnt = state.attachments.length;
            var proposedAttachmentCnt = action.files.length;
            var totalAttachmentCnt = currentAttachmentCnt + proposedAttachmentCnt;
            var currentAttachmentsSize = 0;
            for (let aidx = 0 ; aidx < state.attachments.length ; ++aidx)
            {
                currentAttachmentsSize += state.attachments[aidx].size
            }
            var propsedAttachmentSize = 0;
            for (let aidx = 0 ; aidx < action.files.length ; ++aidx)
            {
                propsedAttachmentSize += action.files[aidx].size
            }
            var totalAttachmentSize = currentAttachmentsSize + propsedAttachmentSize;

            if (totalAttachmentCnt > process.env.REACT_APP_MAX_ATTACHMENTS)
            {
                return Object.assign({}, state, {error:true, errorMsg:'Pick only '+process.env.REACT_APP_MAX_ATTACHMENTS+' file',attachments:[]})
            }
            else if (totalAttachmentSize > process.env.REACT_APP_MAX_ATTACHMENT_SIZE)
            {
                return Object.assign({}, state, {error:true, errorMsg:'Files Must Be Less Than '+(process.env.REACT_APP_MAX_ATTACHMENT_SIZE/1000000)+'MB',attachments:[]})
            }
            else
            {
                return Object.assign({}, state, {error:false, attachments:state.attachments.concat(action.files)})
            }
        }
        case 'FILE_PICKED':{
            return Object.assign({}, state, {error:false, errorMsg:''});
        }
        case 'MEDIA_PICKER_SHOW_ATTACHMENT_THUMBNAILS':
        {
            return {...state, attachmentthumbinfo: action.files}
        }
        case 'MESSAGE_POST_SUCCESS':
        case 'POST_COMPLETE_SUCCESS':
        case 'POST_DRAFT_SUCCESS':
        {
            return Object.assign({}, state, {attachments:[], attachmentthumbinfo:[], error:null})
        }
        default:
            return state;
    }
};

export default mediapicker