const INITIAL_STATE =
    {
        showEndCeenDialog: false,
        showSettingsDrawer:false
    };
const manage = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'INIT_MANAGE': {
            return INITIAL_STATE;
        }
        case 'TOGGLE_SETTINGS_DRAWER':
        {
            return Object.assign({}, state, {showSettingsDrawer:!state.showSettingsDrawer});
        }
        case 'SHOW_END_CEEN_DIALOG':
        {
            return Object.assign({}, state, {showEndCeenDialog:true});
        }
        case 'HIDE_END_CEEN_DIALOG':
        {
            return Object.assign({}, state, {showEndCeenDialog:false});
        }
        default:
            return state
    }
};

export default manage