import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import MapItContainer from '../containers/MapItContainer'
import { useNavigate } from 'react-router-dom';
import InitCoinContainer from "../containers/InitCoinContainer";

const FabButton = props => {
    const navigate = useNavigate();
    return <Fab color='secondary' className='fab' onClick={(e)=>navigate('/transfer')}>
        <AddIcon/>
    </Fab>
}
class Home extends React.Component{
    componentDidMount() {
        var query = new URLSearchParams(window.location.search);
        var tag_hash = query.get('tag_hash');
        var picc_data = query.get('picc_data');
        var enc = query.get('enc');
        var cmac = query.get('cmac');
        this.props.setTagParams(tag_hash, picc_data, enc, cmac);
        this.props.getTransfers();
    }

    render() {
        return (
            <div className='fullscreen'>
                {this.props.transfers && this.props.transfers.length > 0 ?
                    <>
                        <MapItContainer/>
                        <FabButton/>
                    </>
                    :
                    <>
                        <InitCoinContainer/>
                    </>
                }
            </div>
        );
    }
}
export default Home
